<template>
  <div class="div-bar">
    <HideShow :iconSize="50" classCss="text-center">
      <Loading type="line" :center="false" v-if="loading" />
      <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
        :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
        :height="height" />
    </HideShow>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import { mapGetters, mapActions } from "vuex";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);


export default {
  name: "BarChart",
  components: {
    LineChartGenerator,
    Loading,
    HideShow,
  },
  props: {
    filterSelected: {
      type: String,
      default: "year",
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      urlGet: "/api/v1/finance/payable-receivable/balance-finance-dashboard",
      filter: { content: "Hoje", id: "today" },
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: (value, index, values) => {
                return `R$ ${this.formatMoney(value)}`;
              },
              font: {
                size: 14,
              },
              color: "#787878",
            },
          },
          x: {
            ticks: {
              font: {
                size: 14,
              },
              color: "#787878",
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                return `R$ ${this.formatMoney(tooltipItems.raw)}`;
              },
            },
          },
          legend: {
            labels: {
              font: {
                size: 15,
              },
            },
          },
        },
      },
      loading: true,
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("util", ["formatMoney"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGet,
        obj: { filter: this.filter.id },
      };
      this.getApi(params).then((response) => {
        this.chartData.labels = response.content.labels;
        this.chartData.datasets = response.content.datasets;
        this.loading = false;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "filterDashboard") {
          this.filter = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-bar {
  padding: 8px;
  height: 425px;
  padding-top: 30px;
}

.div-title {
  font-size: 15px;
}
</style>