<template>
  <div class="favorite-main">
    <div class="side-by-side icon-tool" @click="openModalFavorite()">
      <i class="fa-duotone fa-screwdriver-wrench"></i>
    </div>
    <div class="side-by-side box-favorite" @click="openNotificarions()">
      <div class="div-icon">
        <!-- <div class="notifications">5</div> -->
        <i class="fa-solid fa-message-lines icon-notification"></i>
      </div>
    </div>
    <div v-for="favorite in favoriteList" class="side-by-side" :key="favorite.url">
      <div class="side-by-side box-favorite" @click="navigateTo(favorite.url)">
        <div class="div-icon">
          <i :class="favorite.icon"></i>
        </div>
      </div>
    </div>
    <Modal title="Configuração" :width="750" v-show="showModal('favorite')" :onHideModal="onHideModal">
      <b-tabs>
        <b-tab title="Dashboard">
          <ConfigDashboard />
        </b-tab>
        <b-tab title="Favoritos">
          <ScrollBar :minHeight="150" :maxHeight="350">
            <div class="div-show" v-for="favorite in favorites" :key="favorite.title">
              <div v-if="isVisible(favorite)">
                <b-row>
                  <b-col sm="2">
                    <div class="text-center">
                      <span class="title"> <i :class="favorite.icon"></i></span>
                    </div>
                  </b-col>
                  <b-col sm="8">
                    <div @click="addOrRemove(favorite)">
                      <span class="title">{{ favorite.title }}</span>
                    </div>
                  </b-col>
                  <b-col sm="2">
                    <div @click="addOrRemove(favorite)">
                      <i v-if="favorite.isFavorite" class="icon-favorite fa-solid fa-star"></i>
                      <i v-else class="icon-favorite fa-regular fa-star"></i>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </ScrollBar>
        </b-tab>
      </b-tabs>
    </Modal>
  </div>
</template>

<script>
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import ConfigDashboard from "../config/ConfigDashboard.vue";

import { mapMutations, mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Favorite",
  props: ["favorite"],
  components: {
    Modal,
    ScrollBar,
    Toggle,
    ConfigDashboard,
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("generic", ["showModal"]),
    ...mapState("user", ["userLogged"]),
  },
  data() {
    return {
      urlCreate: "/api/v1/adm/favorite/create",
      urlGetApi: "/api/v1/adm/favorite/get-all",
      favorites: [
        {
          title: "Cadastrar Cliente",
          icon: "fas fa-user",
          module: "CRM",
          url: "customerCreate",
          ruleId: "30C2B0DF-EB47-4DCF-A8B6-643CD0166318",
          isFavorite: false,
        },
        {
          title: "Emitir Fatura",
          icon: "fa-sharp fa-solid fa-file-invoice",
          module: "Billing",
          url: "invoiceCreate",
          ruleId: "5CF2112A-D81D-4C31-B131-CB0E9FAD32E0",
          isFavorite: false,
        },
        {
          title: "Emitir Locação",
          icon: "fas fa-exchange-alt",
          module: "Rental",
          url: "rentCreate",
          ruleId: "E847C004-9A13-4ADA-80EA-34F3A876CEC3",
          isFavorite: false,
        },
        {
          title: "Cadastrar Produto",
          icon: "fas fa-boxes",
          module: "Stock",
          url: "productSelectOption",
          ruleId: "C429477F-0F93-419B-9023-C5832B90624F",
          isFavorite: false,
        },
        {
          title: "Painel de OS",
          icon: "fa-regular fa-solar-panel",
          module: "Operational",
          url: "osPanelList",
          ruleId: "11B75C86-55EC-45E5-95FA-74318DB46CAA",
          isFavorite: false,
        },
      ],
      favoriteList: [],
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("generic", ["postApi", "getApi"]),
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    openModalFavorite() {
      this.openModal("favorite");
    },
    openNotificarions() {
      this.addEvent({ name: "openNotificarions" });
    },
    navigateTo(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
    isVisible(favorite) {
      if (favorite.module == "CRM" && this.hasRule("CRM")) return true;
      if (favorite.module == "Stock" && this.hasRule("Stock")) return true;
      if (favorite.module == "Billing" && this.hasRule("Billing")) return true;
      if (favorite.module == "Rental" && this.hasRule("Rental")) return true;
      if (favorite.module == "Operational" && this.hasRule("Operational"))
        return true;

      return false;
    },
    addOrRemove(favorite) {
      favorite.userId = this.userLogged.user.id;
      let params = { url: this.urlCreate, obj: favorite, notNotifyToast: true };
      this.postApi(params).then((response) => {
        this.getAll();
      });
    },
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGetApi,
        obj: { any: this.userLogged.user.id },
      };
      this.getApi(params).then((response) => {
        this.favoriteList = [];
        response.content.data.forEach((item) => {
          var favorite = this.favorites.find((x) => x.url == item.url);
          this.favoriteList.push(favorite);
        });

        this.favorites.forEach((item) => {
          var selected = response.content.data.find((x) => x.url == item.url);
          item.isFavorite = false;
          if (selected) {
            if (selected.url == item.url) {
              item.isFavorite = true;
            } else {
              item.isFavorite = false;
            }
          }
        });
      });
    },
    onHideModal() {
      this.addEvent({ name: "updateDashboard" });
    },
  },
};
</script>

<style scoped>
.favorite-main {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.box-favorite {
  height: 65px !important;
  width: 65px !important;
  border: 1px solid #e8eaed;
  background-color: #fff;
  border-radius: 13px;
  margin-left: 18px;
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 400;
  cursor: pointer;
}

.box-favorite:hover {
  background-color: #f1f1f3;
}

.div-icon {
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-left: 10px;
  cursor: pointer;
}

.icon-tool {
  cursor: pointer;
  font-size: 17px;
}

.icon-favorite {
  color: #f1cd54;
  cursor: pointer;
}

.icon-favorite:hover {
  color: #e0bd4a;
}

.notifications {
  width: 25px;
  height: 25px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 50px;
  color: white;
  position: absolute;
  margin-left: 60px;
  margin-bottom: 55px;
}

.icon-notification {
  color: #2b76da;
}

.div-show {
  margin-bottom: 3px;
}
</style>
