<template>
    <div>
        <div class="div-main" v-if="data.length > 0">
            <Alert type="danger">
                <span>
                    ATENÇÃO, existe(m) <b>{{ data.length }}</b> boleto(s) em atraso, regularize e evite
                    suspensão
                    do
                    serviço!
                </span>
                <div class="div-billet">
                    <div v-for="billet in data">
                        Boleto vencido em <b>{{ billet.dueDate | moment("DD/MM/YYYY") }}</b>
                        no valor de <b> {{ billet.value | currency }}</b>!
                        <span class="title-payment" @click="openBillet(billet)">Efetuar pagamento...</span>
                    </div>
                </div>
            </Alert>
        </div>
    </div>
</template>

<script>

import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapActions } from "vuex";

export default {
    name: "Warning",
    components: {
        Alert
    },
    data() {
        return {
            urlGetApi: "/api/v1/adm/asaas/get-all-overdue",
            data: [],
        }
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGetApi, obj: { noPaginated: true } };
            this.getApi(params).then((response) => {
                this.data = response.content;
            });
        },
        openBillet(billet) {
            const link = billet.invoiceUrl;
            window.open(link, '_blank');
        }

    },
}
</script>

<style scoped>
.div-main {
    margin-top: 50px;
}

.div-billet {
    margin-top: 10px;
}

.title-payment {
    color: darkblue;
    cursor: pointer;
}
</style>