var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{attrs:{"module":"Dashboard","showFilter":false,"showSearch":false,"showButtons":false}},[_c('div',{attrs:{"slot":"content-main"},slot:"content-main"},[_c('Warning'),(_vm.userLogged.user.userName == 'UserTemp')?_c('div',[_c('br'),_c('Alert',{attrs:{"type":"warning"}},[_c('span',[_vm._v(" Você está "),_c('b',[_vm._v(" temporariamente ")]),_vm._v(" logado, e algumas funções podem não estar disponíveis! ")])])],1):_c('div',[_c('div',{staticClass:"container-loading"},[(_vm.loading)?_c('div',{staticClass:"div-loading"},[_c('br'),_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"line","center":false}})],1):_vm._e()]),_vm._l((_vm.items),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:item.title},[_c('div',[(item.title == 'Favorito' && item.isShow)?_c('Favorite'):_vm._e(),(item.title == 'Tarefa' && item.isShow)?_c('div',{staticClass:"div-task-dashboard"},[_c('PanelTask')],1):_vm._e()],1),(item.title == 'Filtro' && item.isShow)?_c('div',[_c('SelectFilter')],1):_vm._e(),(_vm.hasRule('Operational') && _vm.isPermission('Operational') &&
            item.title == 'Operacional' &&
            item.isShow
          )?_c('PanelOperational'):_vm._e(),(_vm.hasRule('Billing') && _vm.isPermission('Billing') && item.title == 'Faturamento' && item.isShow
          )?_c('PanelInvoice'):_vm._e(),(_vm.hasRule('Rental') && _vm.isPermission('Rental') && item.title == 'Locação' && item.isShow)?_c('div',{staticClass:"panel-rent"},[_c('PanelRent')],1):_vm._e(),(_vm.hasRule('Billet') && _vm.isPermission('Billet') &&
            item.title == 'Boleto Bancário' &&
            item.isShow
          )?_c('PanelBillet'):_vm._e(),(_vm.hasRule('Signature') && _vm.isPermission('Signature') &&
            item.title == 'Assinatura Digital' &&
            item.isShow
          )?_c('PanelSignature'):_vm._e(),(_vm.hasRule('Finance') && _vm.isPermission('Finance') &&
            item.title == 'Financeiro' &&
            item.isShow
          )?_c('PanelFinance'):_vm._e()],1)})],2)],1)]),(_vm.showModal('comunication'))?_c('Modal',{attrs:{"title":_vm.comunication.title,"width":800,"height":750}},[_c('Comunication',{attrs:{"comunication":_vm.comunication}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }