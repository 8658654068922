<template>
    <div class="div-dash-main">
      <Doughnut
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </template>
  
  <script>
  import { Doughnut } from "vue-chartjs/legacy";
  
  import { mapActions } from "vuex";
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
  } from "chart.js";
  
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
  
  export default {
    name: "DoughnutChart",
    components: {
      Doughnut,
    },
    props: {
      chartId: {
        type: String,
        default: "doughnut-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 200,
      },
      height: {
        type: Number,
        default: 230,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        urlGet: "/api/v1/stock/items-moviment/total-stock-dashboard",
        chartData: {
          labels: ["Pendente", "Agendada", "Pago", "Recebido"],
          datasets: [],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: (tooltipItems) => {
                  return ` ${tooltipItems.raw}%`;
                },
              },
            },
          },
        },
      };
    },
    mounted() {
      this.getAll();
    },
    methods: {
      ...mapActions("generic", ["getApi"]),
      getAll() {
        this.loading = true;
        let params = {
          url: this.urlGet,
        };
        this.getApi(params).then((response) => {
          var ret = {
            backgroundColor: response.content.datasets[0].backgroundColors,
            data: response.content.datasets[0].data,
          };
          this.chartData.datasets.push(ret);
          this.loading = false;
        });
      },
    },
  };
  </script>
  <style scoped>
  .div-dash-main {
    padding: 8px;
  }
  
  .div-title {
    font-size: 15px;
  }
  </style>