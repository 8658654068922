<template>
  <div class="container-main">
    <div class="container-icon side-by-side">
      <i class="fa-solid fa-filter icon-filter"></i>
    </div>
    <div class="container-select side-by-side">
      <SelectStatic :initialValue="{ content: 'Hoje', id: 'today' }" :data="options" v-model="filter" />
    </div>
  </div>
</template>
<script>
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "SelectFilter",
  components: {
    SelectStatic,
  },
  data() {
    return {
      filter: {},
      options: [
        { content: "Hoje", id: "today" },
        { content: "Semana Atual", id: "week" },
        { content: "Mês Atual", id: "month" },
        { content: "Ano Atual", id: "year" },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent"]),
  },
  watch: {
    filter: {
      handler() {
        this.addEvent({ name: "filterDashboard", data: this.filter });
      },
      deep: true,
    },
    event: {
      handler(event) {
        if (event.name == "filterInitialDashboard") {
          if (event.data == "today") this.filter = this.options[0];
          if (event.data == "week") this.filter = this.options[1];
          if (event.data == "month") this.filter = this.options[2];
          if (event.data == "year") this.filter = this.options[3];
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.container-main {
  margin-top: 14px;
  margin-bottom: -20px;
  margin-left: 10px;
}

.container-icon {
  margin-right: 20px;
}

.container-select {
  width: 250px;
}


.icon-filter {
  margin-top: 12px;
  font-size: 16px;
}
</style>