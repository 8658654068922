<template>
  <div class="operational-main">
    <b-row>
      <b-col sm="9">
        <div class="div-title text-left">
          <br />
          <span class="title-main">Boleto Bancário</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-emitted" @click="navegateTo('billetListFilter', 'emitted')">
                <i class="fa-solid fa-share icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.emitido | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Emitido</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-money" @click="navegateTo('billetListFilter', 'registered')">
                <i class="fa-solid fa-chart-mixed-up-circle-dollar icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.registrado | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Registrado</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-danger" @click="navegateTo('billetListFilter', 'delayed')">
                <i class="fa-sharp fa-solid fa-triangle-exclamation icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.emAtraso | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Em Atraso</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-output" @click="navegateTo('billetListFilter', 'paid')">
                <i class="fa-solid fa-dollar-sign icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.liquidado | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Liquidado</div>
            </div>
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="6" xl="4">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-shipping" @click="navegateTo('shipmentReturnList')">
                <i class="fa-sharp fa-solid fa-barcode icon-fontawesome"></i>
              </div>
              <div style="font-size: 18px" class="title text-center">
                {{ data.pendenteRemessa }}
              </div>
              <div class="legend text-center">Remessa pendente</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PanelOperational",
  components: {
    HideShow,
  },
  data() {
    return {
      urlGet: "/api/v1/billing/billet/total-dashboard",
      data: {},
      filter: { content: "Hoje", id: "today" },
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = { url: this.urlGet, obj: { any: this.filter.id } };
      this.getApi(params).then((response) => {
        this.data = response.content;
      });
    },
    navegateTo(routeName, status) {
      this.$router.push({
        name: routeName,
        params: {
          filter: this.filter.id, status: status
        }
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "filterDashboard") {
          this.filter = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
  
<style scoped>
.operational-main {
  margin-left: -10px;
}

.div-title {
  margin-bottom: 15px;
}

.panel-child {
  height: 130px !important;
  width: 94% !important;
  border-radius: 20px;
  margin-left: 18px;
  margin-bottom: 10px;
  padding: 10px;
}

.panel-icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.panel-icon-output {
  background-color: #38a169;
}

.panel-output {
  background-color: #f8f9fa;
}

.panel-icon-money {
  background-color: #0008aa;
}

.panel-icon-emitted {
  background-color: #007bff;
}

.panel-icon-danger {
  background-color: red;
}

.panel-icon-shipping {
  background-color: black;
}

.icon-fontawesome {
  color: #fdfdfd;
  font-size: 14px;
}

.title-main {
  font-size: 20px;
  margin-left: 20px;
}

.total {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 1px !important;
}

.legend {
  font-size: 16px;
}
</style>