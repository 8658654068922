<template>
    <div>
        <div class="div-panel text-left">
            <br>
            <span class="title-main">Financeiro</span>
        </div>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="8">
                <b-row>
                    <b-col sm="12">
                        <div class="text-left div-title">Pagar e Receber</div>
                    </b-col>
                </b-row>
                <div class="div-balance">
                    <Molded>
                        <BalanceFinance />
                    </Molded>
                </div>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4">
                <div class="div-detail">
                    <b-row>
                        <b-col sm="6">
                            <div class="text-left div-sub-title">Contas a Pagar</div>
                        </b-col>
                    </b-row>
                    <Molded>
                        <BillsToPay />
                    </Molded>
                    <br>
                    <b-row>
                        <b-col sm="12">
                            <div class="text-left div-sub-title">Contas a Receber</div>
                        </b-col>
                    </b-row>
                    <Molded>
                        <BillsToReceive />
                    </Molded>
                    <br>
                    <b-row>
                        <b-col sm="12">
                            <div class="text-left div-sub-title">Saldo Bancário</div>
                        </b-col>
                    </b-row>
                    <Molded>
                        <BalanceBank />
                    </Molded>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import BalanceFinance from './BalanceFinance.vue'
import BillsToPay from './BillsToPay.vue'
import BillsToReceive from './BillsToReceive.vue'
import PaymentByStatus from './PaymentByStatus.vue'
import BalanceBank from './BalanceBank.vue'

export default {
    name: "PanelFinance",
    components: { Molded, BalanceFinance, BalanceBank, PaymentByStatus, BillsToPay, BillsToReceive },
}

</script>
<style scoped>
.div-main {
    padding-top: 10px;
    font-size: 15px;
}

.div-detail {
    margin-top: 8px;
}


.div-title {
    margin-left: -4px;
    margin-bottom: 5px;
}

.div-sub-title {
    margin-bottom: 12px;
}

.div-panel {
    margin-bottom: 10px;
    margin-left: -16px;
}

.div-balance {
    margin-top: 15px;
    margin-left: -6px;
}

.div-value-bank {
    margin-top: 10px;
}

.value {
    font-size: 14px;
}

.value-bank {
    font-size: 30px;
}

.title-main {
    font-size: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.total {
    font-size: 20px;
    font-weight: bold;
}

.finance-delay {
    color: red;
}

.finance-today {
    color: #FF8A1B;
}

.finance-scheduled {
    color: #3d4eae;
}

.finance-paid {
    color: #009183;
}

.pay {
    color: red;
}

.receive {
    color: darkblue;
}
</style>