<template>
  <div>
    <div class="div-total">
      <Loading type="line" :center="false" v-show="loading" />
      <div class="text-center">
        <b-row>
          <b-col sm="6">
            <div class="text-left">
              <span class="title-total-rent rejected">
                <i class="fa-sharp fa-solid fa-file-invoice"></i>
                <span class="title">
                  {{ title }}
                </span>
              </span>
            </div>
          </b-col>
          <b-col sm="6" class="text-left">
            <HideShow :iconSize="18">
              <span class="title-total-rent rejected">
                {{ datasets[0] | currency }}
              </span>
            </HideShow>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <div class="text-left">
              <span class="title-total-rent rejected">
                <i class="fa-solid fa-do-not-enter"></i>
                <span class="title">Pendente</span>
              </span>
            </div>
          </b-col>
          <b-col sm="6" class="text-left">
            <HideShow :iconSize="18">
              <span class="title-total-rent rejected">
                {{ datasets[1] | currency }}
              </span>
            </HideShow>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BillsToPay",
  components: {
    Loading,
    HideShow,
  },
  props: {
    filterSelected: {
      type: String,
      default: "year",
    },
  },
  data() {
    return {
      urlGet: "/api/v1/finance/payable-receivable/total-payable-receivable-dashboard",
      filter: { content: "Hoje", id: "today" },
      loading: true,
      datasets: [],
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    title() {
      if (this.filter.id == 'today') return "Hoje";
      if (this.filter.id == 'week') return "Semana";
      if (this.filter.id == 'month') return "Mês";
      if (this.filter.id == 'year') return "Ano";
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = {
        url: this.urlGet,
        obj: { filter: this.filter.id },
      };
      this.getApi(params).then((response) => {
        this.datasets = response.content;
        this.loading = false;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "filterDashboard") {
          this.filter = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.rejected {
  color: #f0134d;
}

.title-total-rent {
  font-size: 18px;
}

.div-total {
  padding: 2px;
  margin: auto;
  margin: 0;
}

.div-title {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
}

.title {
  font-size: 14px;
  margin-left: 10px;
}
</style>