<template>
  <div class="operational-main">
    <b-row>
      <b-col sm="9">
        <div class="div-title text-left">
          <br />
          <span class="title-main">Operacional</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-output" @click="navegateTo(filter.id)">
                <i class="fa-regular fa-truck-ramp-box icon-fontawesome"></i>
              </div>
              <div class="total text-center">{{ data.delivery }}</div>
              <div class="legend text-center">Total Saídas</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-danger">
              <div class="panel-icon panel-icon-danger" @click="navegateTo('delayed')">
                <i class="fa-solid fa-circle-arrow-up icon-fontawesome"></i>
              </div>
              <div class="total text-center">{{ data.deliveryDelayed }}</div>
              <div class="legend text-center">Saídas atraso</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-devolution">
              <div class="panel-icon panel-icon-devolution" @click="navegateTo(filter.id)">
                <i class="fa-regular fa-truck-ramp-box icon-fontawesome"></i>
              </div>
              <div class="total text-center">{{ data.devolution }}</div>
              <div class="legend text-center">Total Retorno</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="2">
            <div class="panel-child panel-end">
              <div class="panel-icon panel-icon-end" @click="navegateTo('delayed')">
                <i class="fa-solid fa-circle-arrow-down icon-fontawesome"></i>
              </div>
              <div class="total text-center">{{ data.devolutionDelayed }}</div>
              <div class="legend text-center">Retornos atraso</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PanelOperational",
  components: {},
  data() {
    return {
      urlGet: "/api/v1/operational/operational-dashboard",
      data: {},
      filter: { content: "Hoje", id: "today" },
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = { url: this.urlGet, obj: { any: this.filter.id } };
      this.getApi(params).then((response) => {
        this.data = response.content;
      });
    },
    navegateTo(filter) {
      this.$router.push({
        name: "osPanelListFilter",
        params: { filter: filter }
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "filterDashboard") {
          this.filter = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
  
<style scoped>
.operational-main {
  margin-left: -10px;
}

.div-title {
  margin-bottom: 10px;
}

.panel-child {
  height: 130px !important;
  width: 95% !important;
  border-radius: 20px;
  margin-left: 18px;
  margin-bottom: 10px;
  padding: 10px;
}

.panel-icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.panel-icon-output {
  background-color: #009183;
}

.panel-output {
  background-color: #d4ebe7;
}

.panel-icon-danger {
  background-color: red;
}

.panel-danger {
  background-color: #fdf7ea;
}

.panel-icon-end {
  background-color: #ff8a1b;
}

.panel-end {
  background-color: #fdf7ea;
}

.panel-icon-devolution {
  background-color: #3d4eae;
}

.panel-devolution {
  background-color: #d0d2e7;
}

.panel-icon-summary {
  background-color: #d1d5da;
}

.panel-summary {
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-fontawesome {
  color: #fdfdfd;
  font-size: 14px;
}

.title-main {
  font-size: 20px;
  margin-left: 20px;
}

.total {
  font-size: 22px;
  font-weight: bold;
  margin-top: 1px;
}

.legend {
  font-size: 16px;
}

.box-calendar {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.legend-summary {
  height: 10px;
}

.total-summary {
  font-size: 18px;
  font-weight: bold;
}

.delivery-delay {
  background-color: red;
}

.delivery {
  background-color: #009183;
}

.devolution {
  background-color: #3d4eae;
}

.devolution-delay {
  background-color: #ff8a1b;
}
</style>