<template>
    <div>
        <div class="div-title-task">Tarefa</div>
        <div class="side-by-side div-task" @click="navegateTo('delayed')">
            <div class="title-task text-center task-delay">PENDENTES EM ATRASO</div>
            <div class="text-center">
                <div class="total-task">
                    {{ data.pending }}
                </div>
                <div class="sub-title-task">
                    Total
                </div>
            </div>
        </div>
        <div class="side-by-side div-task" @click="navegateTo('today')">
            <div class="title-task text-center task-today"> HOJE </div>
            <div class="text-center">
                <div class="total-task">
                    {{ data.today }}
                </div>
                <div class="sub-title-task">
                    Total
                </div>
            </div>
        </div>
        <div class="side-by-side div-task" @click="navegateTo('scheduled')">
            <div class="title-task text-center task-success">PRAZO</div>
            <div class="text-center">
                <div class="total-task">
                    {{ data.scheduled }}
                </div>
                <div class="sub-title-task">
                    Total
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapState } from "vuex";

export default {
    name: "PanelTask",
    data() {
        return {
            urlGet: "/api/v1/shared/job/job-dashboard",
            data: {},
        }
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGet, obj: { id: this.userLogged.user.id } };
            this.getApi(params).then((response) => {
                this.data = response.content;
            });
        },
        navegateTo(filter) {
            this.$router.push({
                name: "jobListFilter",
                params: { filter: filter }
            });
        }
    }
}

</script>
<style scoped>
.div-task {
    margin-left: 6px;
    margin-right: 25px;
    border-radius: 15px;
    width: 130px;
    height: 137px;
    background-color: #F8F9FA;
    cursor: pointer;
}

.div-title-task {
    font-size: 21px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.title-task {
    font-size: 13px;
    border-radius: 15px 15px 0px 0px;
    color: white;
    font-weight: 600;
    padding: 5px;
    height: 60px;
}

.task-today {
    background-color: #FF8A1B;
    font-size: 20px;

}

.task-delay {
    background-color: #FF6362;
}

.task-success {
    background-color: #3D4EAE;
    font-size: 15px;
}

.total-task {
    font-size: 20px;
    font-weight: 600;
}
</style>