<template>
    <div class="rent-main">
        <b-row>
            <b-col sm="9">
                <div class="div-title text-left">
                    <br>
                    <span class="title-main">Locação</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4">
                <b-row>
                    <b-col sm="6">
                        <div class="text-left div-title">Total por Status</div>
                    </b-col>
                </b-row>
                <Molded>
                    <TotalRent />
                </Molded>
                <br>
                <b-row>
                    <b-col sm="12">
                        <div class="text-left div-title">Ranking por Consultor</div>
                    </b-col>
                </b-row>
                <Molded>
                    <RankingCollaborator />
                </Molded>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="8">
                <b-row>
                    <b-col sm="6">
                        <div class="text-left div-title">Balanço de Locação</div>
                    </b-col>
                </b-row>
                <Molded>
                    <BalanceRent />
                </Molded>
            </b-col>
        </b-row>
    </div>
</template>
<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import BalanceRent from './_BalanceRent.vue'
import TotalRent from './_TotalRent.vue'
import RankingCollaborator from './_RankingCollaborator.vue'


export default {
    name: "PanelRent",
    components: { Molded, BalanceRent, TotalRent, RankingCollaborator },
}

</script>
<style scoped>
.rent-main {
    margin-left: -18px;
}

.title-main{
    font-size: 20px;
}

.div-title {
    font-size: 15px;
    margin-bottom: 10px;
}
</style>