<template>
  <div>
    <RadioGroup title="Filtro Inicial" :options="[
      { text: 'Hoje', value: 'today' },
      { text: 'Semana Atual', value: 'week' },
      { text: 'Mês Atual', value: 'month' },
      { text: 'Ano Atual', value: 'year' },
    ]" v-model="filter" />
    <span class="title"> Clique e arraste para ordenar</span>
    <ScrollBar :minHeight="150" :maxHeight="300">
      <div class="molded">
        <b-row>
          <b-col sm="6">
            <i class="fa-solid fa-star icon-favorite"></i>
            <span class="title"> Favorito</span>
          </b-col>
          <b-col sm="6">
            <div class="title text-right">Fixo</div>
          </b-col>
        </b-row>
      </div>
      <draggable v-model="items">
        <div v-for="(item, index) in items" :key="index" class="draggable-item">
          <div class="molded" v-if="isVisible(item)">
            <b-row>
              <b-col sm="6">
                <i class="title" :class="item.icon"></i> <span class="title">{{ item.title }}</span>
              </b-col>
              <b-col sm="6">
                <div v-if="item.title != 'Favorito'" class="div-eye text-right">
                  <span class="title">
                    <i @click="item.isShow = false" v-if="item.isShow" class="fa-solid fa-eye icon-eye"></i>
                    <i @click="item.isShow = true" v-else class="fa-sharp fa-solid fa-eye-slash icon-eye-off"></i>
                  </span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </draggable>
    </ScrollBar>
  </div>
</template>

<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import draggable from "vuedraggable";

import { mapMutations, mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "ConfigDashboard",
  components: {
    ScrollBar,
    Toggle,
    draggable,
    RadioGroup,
  },
  computed: {
    ...mapGetters("user", ["hasRule", "hasPermission"]),
    ...mapState("user", ["userLogged"]),
  },
  data() {
    return {
      urlUpdate: "/api/v1/adm/user/update-profile",
      urlGetById: "/api/v1/adm/user/get-by-id",
      items: [
        {
          order: 0,
          title: "Favorito",
          icon: "fa-solid fa-star",
          module: "Favorite",
          isShow: true,
        },
        {
          order: 1,
          title: "Tarefa",
          icon: "fa-solid fa-check-double",
          module: "all",
          isShow: true,
        },
        {
          order: 2,
          title: "Filtro",
          icon: "fa-solid fa-filter",
          module: "all",
          isShow: true,
        },
        {
          order: 3,
          title: "Operacional",
          icon: "fa-solid fa-truck-loading",
          module: "Operational",
          isShow: true,
        },
        {
          order: 4,
          title: "Faturamento",
          icon: "fa-solid fa-file-invoice",
          module: "Billing",
          isShow: true,
        },
        {
          order: 5,
          title: "Locação",
          icon: "fa-solid fa-light fa-right-left",
          module: "Rental",
          isShow: true,
        },
        {
          order: 6,
          title: "Boleto Bancário",
          icon: "fa-solid fa-barcode",
          module: "Billet",
          isShow: true,
        },
        {
          order: 7,
          title: "Assinatura Digital",
          icon: "fa-solid fa-signature",
          module: "Signature",
          isShow: true,
        },
        {
          order: 8,
          title: "Financeiro",
          icon: "fa-regular fa-sack-dollar",
          module: "Finance",
          isShow: true,
        },
      ],
      filter: "today",
    };
  },
  mounted() {
    this.getById();
  },
  methods: {
    ...mapActions("generic", ["putApi", "getApi"]),
    ...mapMutations("generic", ["addEvent"]),
    isVisible(item) {
      if (item.module == "all") return true;
      if (item.module == "Billet" && this.hasRule("Billet") && this.isPermission("Billet")) return true;
      if (item.module == "Billing" && this.hasRule("Billing") && this.isPermission("Billing")) return true;
      if (item.module == "Rental" && this.hasRule("Rental") && this.isPermission("Rental")) return true;
      if (item.module == "Signature" && this.hasRule("Signature") && this.isPermission("Signature")) return true;
      if (item.module == "Operational" && this.hasRule("Operational") && this.isPermission("Operational"))
        return true;
      if (item.module == "Finance" && this.hasRule("Finance") && this.isPermission("Finance"))
        return true;


      return false;
    },
    isPermission(rule) {
      let hasPermission = this.hasPermission(rule);
      if (hasPermission) {
        if (hasPermission.includes("dashboard")) {
          return true;
        }
        return false;
      }
    },
    save() {
      let dashboard = {
        filter: this.filter,
        order: this.items,
      };
      let obj = {
        id: this.userLogged.user.id,
        dashboard: JSON.stringify(dashboard),
      };
      let params = { url: this.urlUpdate, obj: obj, notNotifyToast: true };
      this.putApi(params).then((response) => { });
    },
    getById() {
      this.loading = true;
      let params = {
        url: this.urlGetById,
        obj: { userId: this.userLogged.user.id },
      };
      this.getApi(params).then((response) => {
        if (response.content.dashboard != null) {
          let self = this;
          setTimeout(function () {
            var retItems = JSON.parse(response.content.dashboard);
            self.filter = retItems.filter;
            let order = 0;
            retItems.order.forEach((dashboard) => {
              self.items.forEach((item) => {
                if (dashboard.title == item.title) {
                  item.order = order;
                  item.isShow = dashboard.isShow;
                  order++;
                }
              });
            });
            self.items = self.order(self.items);
          }, 300);
        }
      });
    },
    order(items) {
      return items.sort((a, b) => a.order - b.order);
    }

  },
  watch: {
    items: {
      handler() {
        this.save();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.save();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.molded {
  border: 1px solid #e8eaed;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  cursor: grab;
}

.div-eye {
  cursor: pointer;
}

.icon-eye {
  color: #0d40e9;
}

.icon-eye-off {
  color: darkgray;
  opacity: 0.4;
}

.icon-favorite {
  color: #f1cd54;
  cursor: pointer;
}
</style>