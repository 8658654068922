<template>
    <div class="operational-main">
        <b-row>
            <b-col sm="9">
                <div class="div-title text-left">
                    <br />
                    <span class="title-main">Assinatura Digital</span>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-signature text-center" @click="navegateTo('signatureList', 'signed')">
                            <div class="div-signature-icon icon-signature-success side-by-side">
                                <i class="fa-solid fa-circle-check"></i>
                            </div>
                            <div class="div-signature-subtitle side-by-side">
                                {{ data.signed }}
                                <span>Assinados</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-signature text-center" @click="navegateTo('signatureList', 'pending')">
                            <div class="div-signature-icon icon-signature-peding side-by-side">
                                <i class="fa-duotone fa-loader"></i>
                            </div>
                            <div class="div-signature-subtitle side-by-side">
                                {{ data.pending }}
                                <span>Pendente</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-signature text-center" @click="navegateTo('signatureList', 'refused')">
                            <div class="div-signature-icon icon-signature-danger side-by-side">
                                <i class="fa-solid fa-xmark-large"></i>
                            </div>
                            <div class="div-signature-subtitle side-by-side">
                                {{ data.refused }}
                                <span>Recusados</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
    
<script>
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "PanelSignature",
    components: {
        HideShow,
    },
    data() {
        return {
            urlGet: "/api/v1/signature-file/total-dashboard",
            data: {},
            filter: { content: "Hoje", id: "today" },
        };
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGet, obj: { any: this.filter.id } };
            this.getApi(params).then((response) => {
                this.data = response.content;
            });
        },
        navegateTo(routeName, status) {
            this.$router.push({
                name: routeName,
                params: {
                    filter: this.filter.id, status: status
                }
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "filterDashboard") {
                    this.filter = event.data;
                    this.getAll();
                }
            },
            deep: true,
        },
    },
};
</script>
    
<style scoped>
.title-main {
    font-size: 20px;
    margin-left: 10px;
}

.panel-signature {
    background-color: #F8F9FA;
    border-radius: 20px;
    height: 100px;
    margin-top: 15px;
    margin-left: 3px;
    cursor: pointer;
}

.div-signature-icon {
    font-size: 20px;

}

.div-signature-subtitle {
    font-size: 18px;
    margin: 30px;
}

.icon-signature-success {
    color: #38A169;
}

.icon-signature-peding {
    color: #FFC106;
}

.icon-signature-danger {
    color: red;
}
</style>